export default {
  frontPage: 'Etusivu',
  cart: 'Ostoskori',
  requestQuote: 'Pyydä tarjous',
  sendQuoteRequest: 'Lähetä tarjouspyyntö',
  businessCustomer: 'Yritysasiakas',
  message: 'Viesti',
  quoteCart: 'Tarjouskori',
  price: 'Hinta',
  ownAccount: 'Oma tili',
  login: 'Kirjaudu sisään',
  name: 'Nimi',
  firstname: 'Etunimi',
  lastname: 'Sukunimi',
  email: 'Sähköposti',
  phoneNumber: 'Puhelinnumero',
  password: 'Salasana',
  company: 'Yritys',
  streetAddress: 'Katuosoite',
  postcode: 'Postikoodi',
  city: 'Postitoimipaikka',
  country: 'Maa',
  finland: 'Suomi',
  buttonCreateAccount: 'Luo tili',
  accountCreatedSuccessfully: 'Tili luotu onnistuneesti',
  accountCreatingError: 'Virhe luodessa tiliä, yritä uudelleen',
  register: 'Luo tili',
  loggedOut: 'Olet kirjautunut ulos',
  accountNotVerified: 'Tiliä ei ole vahvistettu',
  passwordInfo: 'Syötä vähintään 8 merkkiä, käyttäen pieniä ja isoja kirjaimia, numeroita sekä erikoismerkkejä.',
  hiFirstname: 'Hei {firstname}',
  ownDetails: 'Omat tiedot',
  checkUserDetails: 'Tarkastele tietojasi',
  orders: 'Tilaukset',
  orderInfo: 'Täältä löydät tekemäsi tilaukset.',
  seeOrders: 'Katso tilauksesi',
  marketingPermissions: 'Markkinointiluvat',
  editMarketingPermissions: 'Hallitse lupiasi',
  wishlists: 'Toivelistat',
  browseYourWishlists: 'Selaa toivelistojasi',
  noWishlists: 'Sinulla ei vielä ole toivelistaa.',
  errorOnLogin: 'Virhe kirjautumisessa, yritä uudelleen',
  emailNotVerified: 'Sähköpostia ei ole vahvistettu',
  checkEmailAndPassword: 'Virheellinen sähköposti tai salasana',
  close: 'Sulje',
  logOut: 'Kirjaudu ulos',
  logIn: 'Kirjaudu sisään',
  images: 'Kuvat',
  addToCart: 'Lisää ostoskoriin',
  amountInCart: '{n} ostoskorissa',
  amountInQuoteCart: '{n} tarjouskorissa',
  backToProduct: 'Takaisin tuottelle',
  largeImages: 'Suuret kuvat',
  threeDImage: '3D-kuva',
  video: 'Video',
  imagesFromCustomers: 'Asiakaskuvat',
  amountOfReviews: 'Ei suositteluja | 1 suosittelu | {n} suosittelua',
  writeAReview: 'Kirjoita arvostelu',
  addToWishlist: 'Lisää toivelistalle',
  removeFromWishlist: 'Poista toivelistalta',
  customerService: 'Asiakaspalvelu',
  cartEmpty: 'Ostoskorisi on tyhjä',
  pcs: 'kpl | {n} kpl | {n} kpl',
  quantity: 'Määrä',
  removeDiscountCode: 'Poista alennuskoodi',
  proceedToCheckout: 'Siirry kassalle',
  summary: 'Yhteenveto',
  clearCart: 'Tyhjennä ostoskori',
  clearQuoteCart: 'Tyhjennä tarjouskori',
  shareCart: 'Jaa ostoskori',
  error: 'Virhe',
  specialOffers: 'Tarjoukset',
  closeNotification: 'Sulje ilmoitus',
  satisfiedCustomers: 'Tyytyväiset asiakkaat',
  fastDeliveries: 'Nopeat toimitukset',
  paymentFlexibility: 'Monipuoliset maksutavat',
  reliableDomesticCompany: 'Luotettava kotimainen yritys',
  products: 'Tuotteet',
  menu: 'Valikko',
  stores: 'Myymälät',
  campaigns: 'Kampanjat',
  showPassword: 'Näytä salasana',
  hidePassword: 'Piilota salasana',
  myAccount: 'Oma tili',
  saveDetails: 'Tallenna tiedot',
  address: 'Osoite',
  deleteAddress: 'Poista osoite',
  editAddress: 'Muokkaa osoitetta',
  addAddress: 'Lisää osoite',
  saveAddress: 'Tallenna osoite',
  defaultShippingAddress: 'Ensisijainen toimitusosoite',
  defaultBillingAddress: 'Ensisijainen laskutusosoite',
  defaultBillingAndShippingAddress: 'Ensisijainen toimitus- ja laskutusosoite',
  validator: {
    required: 'Vaadittu kenttä',
    numeric: 'Syötä vain numeroita',
    postcode: {
      minLength: 'Syötä {n} numeroa'
    },
    phone: {
      minLength: 'Puhelinnumeron tulee olla vähintään {n} merkkiä pitkä',
      format: 'Kirjoita puhelinnumero muodossa +358401234567',
      countryCode: 'Aloita puhelinnumero maakoodilla (esim. +358)'
    },
    email: {
      format: 'Tarkista sähköpostiosoitteen muoto'
    }
  }
}
